/* smidgens @ github */

/*
Utility classes for prototyping.
*/

/* layout */
.contain { object-fit: contain; }

/* background */
.bg-red { background:#f00; }
.bg-green { background:#0f0; }
.bg-blue { background:#00f; }
.bg-yellow { background:#ff0; }
.bg-magenta { background:#f0f; }
.bg-purple { background:purple; }
.bg-orange { background:orange; }

.bg-dark { background:#111; }



.shadow {
	filter:drop-shadow(5px 5px 4px #0003) !important;
}