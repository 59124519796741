/* smidgens @ github */

/* global override */

body {
	margin: 0;
	width:100%;
	height:100%;

	background:#111;

	font-family: sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	
}

div { box-sizing: border-box; }

/*
============================
========== INPUT ===========
============================
*/

input {
	width:100%;
	border-radius: 0.25em;
	padding:0.5em;
	background:#fff;
	border:1px solid #0005;
	cursor:default;
}

input:read-only { background:#eee; }

.textarea {
	width:100%;
	resize: vertical;
	min-height: 1em;
}

/*
============================
========== SCROLL ==========
============================
*/

/* width */
::-webkit-scrollbar {
	width: 10px;
	/* border-left:1px solid #444; */
	
}

/* Track */
::-webkit-scrollbar-track {
	background:#0002;
	
	/* background: #f1f1f1; */
	
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #fff2;
	/* border-left:1px solid #444; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #fff3;
}


/*
============================
========== RANGE ===========
============================
*/


input[type="range"] {
	-webkit-appearance: none;
	padding:0.5em 0;
	box-sizing: border-box;
	cursor:pointer;
	background:#fff0;
	margin: 0;
}

input[type="range"]:focus {
	outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
	background: #777;
	height: 3px;
}

input[type="range"]::-moz-range-track {
	background: #fff;
	height: 3px;
}


input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	height: 15px;
	width: 15px;
	/* background: pink; */

	background:#fff;
	margin-top: -5px;
	border-radius: 50%;
}

input[type="range"]::-moz-range-thumb {
	height: 15px;
	width: 15px;
	/* background: pink; */
	margin-top: -5px;
	border-radius: 50%;
	background:#fff;

}